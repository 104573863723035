.wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    background: linear-gradient(180deg, #0E0E25 0%, #14142F 100%);
}

.content {
    padding-top: 158px;
    padding-bottom: 200px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.title {
    font-size: 64px;
    line-height: 72px;
    font-weight: bold;
    color: white;
    margin: auto;
    margin-bottom: 30px;
    max-width: 800px;
}

.unique_title {
    font-size: 64px;
    line-height: 72px;
    font-weight: bold;
    color: white;
    margin: auto;
    margin: 151px 0 30px 0;
    max-width: 800px;
}

.unique_item_title {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin: 20px 0 0 0;
}

.unique_item_text {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #B9B9DB;
    max-width: 226px;
    margin: 14px auto;
}

.unique_block {
    width: 100%;
}

.time_block {
    width: 100%;
}

.unique_block {
    display: flex;
    gap: 34px;
    flex-wrap: wrap;
    justify-content: center;
}

.unique_item {
    background: linear-gradient(62.71deg, rgba(255, 255, 255, 0.064) 0%, rgba(255, 255, 255, 0.016) 84.52%);
    box-shadow: 0px 8px 60px rgba(9, 9, 41, 0.2);
    border-radius: 15px;
    width: 316px;
    height: 279px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
    /* justify-content: center; */
}

.time_container {
    padding: 109px 90px 188px 90px;
    background: linear-gradient(62.71deg, rgba(255, 255, 255, 0.064) 0%, rgba(255, 255, 255, 0.016) 84.52%);
    box-shadow: 0px 8px 60px rgba(9, 9, 41, 0.2);
    border-radius: 24px;
    max-width: 1180px;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 100px;
    row-gap: 65px;
}

.item {
    width: 312px;
    text-align: center;
}

.item_value {
    font-weight: 500;
    font-size: 74px;
    line-height: 83px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    text-shadow: 0px 0px 60px rgba(255, 255, 255, 0.66);
    margin: 0;
}

.item_text {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #B9B9DB;
    margin: auto;
    margin-top: 10px;
}

.sup {
    font-size: 30px;
    margin-left: 10px;
}

.badge {
    background: linear-gradient(44.98deg, rgba(98, 98, 254, 0) 14.65%, rgba(97, 97, 247, 0.1198) 23.12%, rgba(94, 93, 229, 0.2882) 35.03%, rgba(88, 87, 199, 0.4854) 48.98%, rgba(81, 79, 157, 0.7045) 64.48%, rgba(72, 69, 104, 0.9389) 81.06%, #454259 85.38%);
    border-radius: 16px;
    font-size: 30px;
    line-height: 38px;
    color: white;
    width: 643px;
    height: 158px;
    padding: 0 80px;
    margin: auto;
    margin-top: -79px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.features_img {
    height: 70px;
}

@media (max-width: 768px) {
    .content {
        width: 100%;
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 73px;
        padding-bottom: 60px;
        box-sizing: border-box;
    }

    .title {
        font-size: 30px;
        line-height: 32px;
    }

    .unique_title {
        font-size: 30px;
        line-height: 32px;
    }

    .item_value {
        font-size: 44px;
        line-height: 52px;
    }

    .item_text {
        font-size: 16px;
        line-height: 20px;
    }

    .sup {
        font-size: 16px;
        margin-left: 5px;
    }

    .unique_item_title {
        font-size: 16px;
        line-height: 20px;
    }

    .unique_item_text {
        font-size: 16px;
        line-height: 20px;
    }
}

@media (max-width: 1400px) {
    .time_container {
        max-width: 800px;
        padding: 100px 60px;
    }
}

@media (max-width: 900px) {

    .time_container {
        max-width: 500px;
        padding: 65px 14px 130px 14px;
    }

    .badge {
        background: linear-gradient(44.98deg, rgba(98, 98, 254, 0) 14.65%, rgba(97, 97, 247, 0.1198) 23.12%, rgba(94, 93, 229, 0.2882) 35.03%, rgba(88, 87, 199, 0.4854) 48.98%, rgba(81, 79, 157, 0.7045) 64.48%, rgba(72, 69, 104, 0.9389) 81.06%, #454259 85.38%);
        border-radius: 16px;
        font-size: 16px;
        line-height: 20px;
        color: white;
        width: 232px;
        height: 100px;
        padding: 0 20px;
        margin: auto;
        margin-top: -50px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}
.wrapper {
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
}

.form_wrapper {
    width: 1360px;
    padding-top: 240px;
    padding-bottom: 240px;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    margin-bottom: 240px;
}

.form_title {
    font-size: 64px;
    font-weight: bold;
    color: white;
}

.form_group {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.form_group div {
    display: flex;
    justify-content: center;
}

.input_sect {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.form_label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    letter-spacing: 0.05em;
    color: white;
}

.form_input {
    width: 542px;
    height: 45px;
    border: 1px solid rgba(255, 255, 255, 0.33);
    background: transparent;
    box-sizing: border-box;
    outline: none;
    border-radius: 4px;
    color: #B9B9DB;
    padding-left: 16px;
    padding-right: 16px;
}

.form_input:focus {
    border: 2px solid #6A4DFD;
    filter: drop-shadow(0px 0px 8px #6A4DFD);
}

.form_input:hover {
    border: 2px solid #6A4DFD;
    /* filter: drop-shadow(0px 0px 8px #6A4DFD); */
}

.form_textarea {
    width: 542px;
    height: 150px;
    border: 1px solid rgba(255, 255, 255, 0.33);
    background: transparent;
    box-sizing: border-box;
    outline: none;
    border-radius: 4px;
    color: #B9B9DB;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
}

.form_textarea:focus {
    border: 2px solid #6A4DFD;
    filter: drop-shadow(0px 0px 8px #6A4DFD);
}

.form_textarea:hover {
    border: 2px solid #6A4DFD;
    /* filter: drop-shadow(0px 0px 8px #6A4DFD); */
}

.form_submit {
    width: 542px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.15em;
    font-weight: bold;
    color: white;
    background-color: #6A4DFD;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 2px;
    cursor: pointer;
}

.form_submit:hover {
    filter: drop-shadow(0px 0px 8px #6A4DFD);
    transform: translate(0, -2px);
}

@media (max-width: 768px) {
    .form_wrapper {
        /* width: 81%; */
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 100px;
        padding-bottom: 120px;
    }

    .form {
        margin-bottom: 120px;
    }

    .form_title {
        font-size: 41px;
    }

    form {
        width: 100%;
    }

    .form_group {
        width: 100%;
    }

    .input_sect {
        width: 100%;
    }

    .form_input {
        width: 100%;
    }

    .form_textarea {
        width: 100%;
    }

    .form_submit {
        width: 100%;
    }
}
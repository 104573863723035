.top_bar {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #0E0E25 0%, #14142F 100%);
    /* height: 100vh; */
    /* background-image: url("/images/top_back.png"); */
}

/* .top_back {
    width: 100%;
} */

.top_video_wrapper {
    display: flex;
    height: 100%;
    justify-content: center;
    overflow-x: hidden;
}

.top_video {
    height: 100%;
    width: auto;
}

.top_content_wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.top_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.top_content_title {
    font-size: 50px;
    max-width: 900px;
    font-weight: 700;
    margin-bottom: 0;
}

.top_content_text {
    font-size: 22px;
    color: #B9B9DB;
    margin-bottom: 24px;
    max-width: 1028px;
}

@media (max-width: 768px) {
    /* .top_video_wrapper video {
        display: none;
    } */

    .top_content {
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
        box-sizing: border-box;
    }

    .top_content_title {
        font-size: 32px;
        line-height: 34px;
        margin-bottom: 10px;
    }

    .top_content_text {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.05em;
        margin-bottom: 20px;
    }
}
.wrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #282c34;
    background: linear-gradient(180deg, #0E0E25 0%, #14142F 100%);
    ;
}

.content {
    width: 1360px;
    padding-top: 120px;
    padding-bottom: 150px;
    z-index: 2;
}

@media (max-width: 768px) {
    .content {
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 40px;
        padding-bottom: 42px;
        box-sizing: border-box;
    }
}
.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 200px;
    padding: 10px;
    box-sizing: border-box;
}

.content {
    width: 85%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.logo_sect {
    display: flex;
    gap: 10px;
}

.logo_image {
    height: 48px;
}

.logo_title {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    color: white;
}

.menus_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.menus {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    font-size: 16px;
}

.menu a {
    color: #B9B9DB;
}

.menu :hover {
    color: #6A4DFD;
}

.links {
    display: flex;
    gap: 60px;
    flex-wrap: wrap;
}

.links_sub {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.menu_title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.03em;
    color: white;
}

.menu_text {
    font-size: 16px;
    color: #B9B9DB;
}

.link_sect {
    display: flex;
    align-items: center;
    gap: 10px;
}

.link_sect :hover {
    color: #6A4DFD;
}

.link_image {
    height: 18px;
}

.bottom {
    align-self: center;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1em;
    color: rgba(185, 185, 219, 0.5);
}

@media (max-width: 768px) {
    .wrapper {
        gap: 100px;
    }

    .bottom {
        align-self: start;
        text-align: start;
    }

    .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 50px;
    }

    .menus {
        gap: 15px;
    }

    .links {
        gap: 40px;
    }

    .links_sub {
        gap: 24px;
    }
}
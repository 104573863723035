.wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    background: linear-gradient(180deg, #0E0E25 0%, #14142F 100%);
}

.content {
    padding-top: 158px;
    padding-bottom: 200px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.title {
    font-size: 64px;
    line-height: 72px;
    font-weight: bold;
    color: white;
    margin: auto;
    max-width: 800px;
}

.benefit_block {
    width: 100%;
    padding-top: 70px;
}

.benefit_title {
    background: linear-gradient(44.98deg, rgba(98, 98, 254, 0) 14.65%, rgba(97, 97, 247, 0.1198) 23.12%, rgba(94, 93, 229, 0.2882) 35.03%, rgba(88, 87, 199, 0.4854) 48.98%, rgba(81, 79, 157, 0.7045) 64.48%, rgba(72, 69, 104, 0.9389) 81.06%, #454259 85.38%);
    border-radius: 10px;
    font-size: 20px;
    line-height: 26px;
    color: white;
    width: 235.07px;
    height: 60px;
    margin: auto;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.benefit_items {
    background: linear-gradient(62.71deg, rgba(255, 255, 255, 0.064) 0%, rgba(255, 255, 255, 0.016) 84.52%);
    box-shadow: 0px 8px 60px rgba(9, 9, 41, 0.2);
    border-radius: 24px;
    padding: 48px 94px;
    width: fit-content;
    margin: auto;
    margin-top: -30px;
    display: flex;
    gap: 30px;
    max-width: 1000px;
    flex-wrap: wrap;
    justify-content: center;
}

.use_container {
    max-width: 1200px;
    display: flex;
    gap: 30px;
    justify-content: center;
    border: 1px solid rgba(255, 255, 255, 0.33);
    border-radius: 14px;
    padding: 100px 80px;
    margin: auto;
    margin-top: 36px;
    align-items: center;
    flex-wrap: wrap;
}

.use_item {
    width: 218px;
    height: 144px;
}

.use_text {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    margin: 30px 0 0 0;
}

.item {
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 12px;
    display: flex;
    width: 340px;
    height: 106px;
    padding: 20px 0 0 23px;
    box-sizing: border-box;
}

.description {
    font-size: 20px;
    line-height: 24px;
    color: white;
    margin: 5px 0 0 23px;
    letter-spacing: 0.03em;
    font-weight: 700;
    width: fit-content;
    text-align: left;
}

.next_down {
    display: none;
}

@media (max-width: 520px) {
    .next_down {
        display: block;
    }

    .use_container {
        flex-direction: column;
    }
}

@media (max-width: 420px) {
    .description {
        font-size: 12px;
        line-height: 20px;
        margin-top: 0;
    }

    .item {
        width: 100%;
        height: auto;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 12px;
    }

    .title {
        max-width: 350px;
        margin-bottom: 0;
    }

    .benefit_block {
        padding-top: 34px;
    }
}

@media (max-width: 768px) {
    .content {
        width: 100%;
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 73px;
        padding-bottom: 60px;
        box-sizing: border-box;
    }

    .title {
        font-size: 30px;
        line-height: 32px;
    }

    .benefit_items {
        padding: 48px 14px;
    }

}

@media (max-width: 1400px) {

    .benefit_items {
        max-width: 800px;
        padding: 48px 60px;
    }

    .use_container {
        max-width: 800px;
        padding: 100px 60px;
    }

    .next_left {
        display: none;
    }

}

@media (max-width: 900px) {

    .benefit_items {
        max-width: 500px;
        padding: 48px 14px;
    }

    .use_container {
        max-width: 500px;
        padding: 100px 10px;
    }

}
.wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    background: linear-gradient(180deg, #0E0E25 0%, #14142F 100%);
}

.description {
    color: white;
    font-size: 30px;
}

.content {
    width: 1000px;
    padding-top: 120px;
    /* padding-bottom: 200px; */
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 80px;
    font-weight: bold;
    color: white;
    margin-bottom: 50px;
}

.advisors {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 10px;
    align-items: baseline;
}

.advisor {
    /* width: 22%; */
    width: 250px;
    /* flex: 1; */
    /* height: 140px;
    background: linear-gradient(74.55deg, rgba(255, 255, 255, 0.05) 0.07%, rgba(255, 255, 255, 0) 99.92%);
    border-radius: 8px; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
}

@media (max-width: 768px) {
    .content {
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .title {
        font-size: 30px;
        line-height: 32px;
        margin-bottom: 32px;
    }

    .description {
        font-size: 18px;
    }

    .advisors {
        row-gap: 24px
    }

    .advisor {
        width: 46%;
    }
}

.advisor_img {
    width: 140px;
    height: 140px;
    /* left: 752.23px;
    top: 9340.07px; */
    /* background: url(image.png); */
    border-radius: 300px;
}

.advisor_name {
    /* font-family: 'Space Grotesk'; */
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */
    text-align: center;
    color: #FFFFFF;
    margin-top: 30px;
}

.advisor_desc {
    /* font-family: 'Space Grotesk'; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    max-width: 229px;
    /* or 150% */
    text-align: center;
    color: #B9B9DB;
    margin-top: 12px;
}

/* .advisor_name_desc_wrapper {
    height: 150px;
} */

@media (max-width: 350px) {
    .advisor {
        width: 100%;
    }

    .advisor_img {
        width: 140px;
        height: 140px;
        /* left: 752.23px;
        top: 9340.07px; */
        /* background: url(image.png); */
        border-radius: 300px;
    }
}

@media (max-width: 470px) {
    .advisor {
        min-width: 100%;
    }

    .advisor_img {
        width: 140px;
        height: 140px;
        /* left: 752.23px;
        top: 9340.07px; */
        /* background: url(image.png); */
        border-radius: 300px;
        margin-top: 20px;
    }
}

@media (max-width: 600px) {
    /* .advisor_name_desc_wrapper {
        height: 160px;
    } */
}
.wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    background: linear-gradient(180deg, #0E0E25 0%, #14142F 100%);
}

.content {
    width: 1360px;
    padding-top: 209px;
    padding-bottom: 120px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
}

.title {
    font-size: 64px;
    font-weight: bold;
    color: white;
    margin-bottom: 75px;
}

.artists1 {
    display: flex;
    flex-direction: column;
    gap: 77px;
    margin-bottom: 120px;
}

.artist1_wrapper {
    display: flex;
    background: linear-gradient(75.18deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 99.16%);
    border-radius: 14px;
    overflow: hidden;
}

.artist1_content_wrapper {
    width: 50%;
    order: 1;
}

.artist1_content_wrapper_right {
    order: 2 !important;
}

.artist1_content {
    padding-left: 60px;
    padding-top: 37px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    color: white;
}

.artist1_content_right {
    padding-left: 30px;
    padding-right: 60px;
    align-items: flex-end !important;
    text-align: right !important;
}

.artist1_name {
    font-size: 36px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 9px;
}

.artist1_description {
    font-size: 18px;
    color: #B9B9DB;
    width: 92%;
}

.artist1_image_wrapper {
    width: 50%;
    order: 2;
}

.artist1_image_wrapper_right {
    width: 50%;
    order: 1 !important;
}

.artist1_image {
    width: 100%;
}

.artists2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 30px;
    margin-bottom: 80px;
}

.artist2 {
    /* width: 23%; */
    width: 316px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(75.18deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 99.16%);
    border-radius: 14px;
}

/* .artist2:hover {
    background: #6A4DFD;
}

.artist2:hover .artist2_image,
.artist2:hover .artist2_mark,
.artist2:hover .artist2_mark_contain,
.artist2:hover .artist2_name {
    display: none;
}

.artist2:hover .artist2_description {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
} */

.artist2_image_contain {
    max-height: 180px;
    margin-bottom: 20px;
    overflow: hidden;
}

.artist2_image {
    width: 100%;
    border-radius: 18px 18px 0 0;
}

.artist2_mark {
    margin-bottom: 5px;
    max-height: 50px;
}

.artist2_mark_contain {
    min-height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.artist2_name {
    font-size: 23px;
    line-height: 24px;
    font-weight: bold;
    max-width: 264px;
    color: white;
    margin-bottom: 15px;
}

.artist3_name {
    font-size: 22px;
    font-weight: bold;
    color: white;
    margin-bottom: 15px;
}

.artist2_description {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
    /* padding-top: 48px; */
    padding-left: 16px;
    padding-right: 16px;
    display: none;
}

.manymore {
    border: 1px solid rgba(255, 255, 255, 0.33);
    box-sizing: border-box;
    border-radius: 14px;
    font-size: 36px;
    font-weight: bold;
    color: white;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 12px;
    padding-right: 12px;
}

@media (max-width: 1100px) {
    .artist1_name {
        font-size: 30px;
    }

    .artist1_description {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .content {
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 50px;
        padding-bottom: 100px;
        box-sizing: border-box;
    }

    .title {
        font-size: 32px;
        line-height: 34px;
        margin-bottom: 34px;
    }

    .artists1 {
        gap: 35px;
        margin-bottom: 48px;
    }

    .artist1_wrapper {
        flex-direction: column;
    }

    .artist1_content_wrapper {
        width: 100%;
        order: 2 !important;
    }

    .artist1_content {
        /* padding-left: 30px; */
        /* padding-bottom: 30px; */
        padding: 15px;
        gap: 7px;
        align-items: center;
        text-align: center;
    }

    .artist1_content_right {
        align-items: center !important;
        text-align: center !important;
    }

    .artist1_name {
        font-size: 30px;
    }

    .artist1_description {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.03em;
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
    }

    .artist1_image_wrapper {
        width: 100%;
        order: 1 !important;
    }

    .artists2 {
        gap: 0px;
        row-gap: 24px;
        margin-bottom: 45px;
    }

    /* .artist2_image_contain {
        max-height: 120px;
    } */

    /* .artist2 {
        width: 47%;
        height: auto;
    } */

    /* .artist2:hover .artist2_image,
    .artist2:hover .artist2_mark,
    .artist2:hover .artist2_name {
        display: block;
    }

    .artist2:hover .artist2_description {
        display: none;
    } */

    .artist2_image {
        /* margin-bottom: 12px; */
    }

    /* .artist2_mark {
        max-height: 30px;
    } */

    /* .artist2_name {
        font-size: 14px;
        line-height: 16px;
    } */

    .artist3_name {
        font-size: 14px;
        line-height: 16px;
    }

    .artist2_description {
        padding-bottom: 48px;
    }

    .manymore {
        font-size: 16px;
        line-height: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.button {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 20px;
    font-weight: bold;
    line-height: 25px;
    background-color: #6A4DFD;
    color: white;
    border-radius: 6px;
}

.button:hover {
    filter: drop-shadow(0px 0px 8px #6A4DFD);
    transform: translate(0, -2px);
}

@media (max-width: 360px) {
    .button {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        background-color: #6A4DFD;
        color: white;
        border-radius: 6px;
    }
}
.button {
    display: flex;
    cursor: pointer;
}

.button:hover {
    filter: drop-shadow(0px 0px 8px #6A4DFD);
    transform: translate(0, -2px);
}

.title {
    padding-left: 34px;
    padding-right: 34px;
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.15em;
    background-color: #6A4DFD;
    color: white;
    border-radius: 6px;
}